import React, { useEffect, useState } from 'react';
import logo from './logo_header.jpg';
import './App.css';
const API_URI = "https://apibarplas.control-presencial.com/api";

function App() {
  const [loading, setLoading] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);

  const [loadingData, setLoadingData] = useState(true);
  const [data, setData] = useState(null);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [userid, setUserid] = useState(-1);
  const [name, setName] = useState('');
  const [accessToken, setAccessToken] = useState('');

  const [errorMsg, setErrorMsg] = useState('');

  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [status, setStatus] = useState(null);
  
  const bootstrapAsync = async () => {
    setLoading(true);
    //const token = await localStorage.getItem("accessToken");
    const renewalToken = await localStorage.getItem("refreshToken");
    if(renewalToken && renewalToken !== '')
      await refreshToken();
    setLoading(false);
  }

  useEffect(() => {
      bootstrapAsync();
  }, [])

  /*const loadDetalles = async () => {
    setLoadingData(true);
    try {
      const token = await localStorage.getItem("accessToken");
      let response = await fetch(API_URI+'/usuario/get', {
                              method: 'GET',
                              headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer '+token,
                              }
                            });
      if (response.status >= 200 && response.status < 300){
        let res = await response.text();
        if(res){
          const resp = JSON.parse(res);
          //console.log(resp);
          await setData(resp);
        }
        setLoadingData(false);
      }else if(response.status == 401){
        refreshToken();
      }else{
        throw(response)
      }
    } catch(error) {
      setLoadingData(false);
      console.log(error);
    }
  }*/

  const cerrarSesion = () => {
    setUserid(-1);
    setName('');
    localStorage.setItem("accessToken", null);
    localStorage.setItem("refreshToken", null);
  }

  const refreshToken = async () =>{
      const token = await localStorage.getItem("accessToken");
      const renewalToken = await localStorage.getItem('refreshToken');
      let response2 = await fetch(API_URI+'/login/refresh', {
                              method: 'POST',
                              headers: {
                              'Accept': 'application/json',
                              'Content-Type': 'application/json',
                              },
                              body: JSON.stringify({
                              'AccessToken': token,
                              'RefreshToken': renewalToken,
                              })
                          });
      if (response2.status >= 200 && response2.status < 300){
          let res = await response2.text();
          if(res){
              const resp = JSON.parse(res);
              setUserid(resp.userid);
              setName(resp.username);
              await setAccessToken(resp.accessToken);
              await setData(resp.registro);
              setLoadingData(false);
              localStorage.setItem("accessToken", resp.accessToken != null ? resp.accessToken : '')
              localStorage.setItem("refreshToken", resp.refreshToken != null ? resp.refreshToken : '')
              //setLoadingBtn(false);
          }
      }else{
          console.log("fallo al refrescar el token de acceso");
          cerrarSesion();
      }
  }

  const onLoginClick = async (e) => {
    e.preventDefault();
    if(username === ''){
      setErrorMsg('Debe introducir un nombre de usuario');
    }else if(password === ''){
      setErrorMsg('Debe introducir la clave pin');
    }else{
      setErrorMsg('');
      try {
        setLoadingBtn(true);
        let response = await fetch(API_URI+'/login/authenticate', {
                                method: 'POST',
                                headers: {
                                  'Accept': 'application/json',
                                  'Content-Type': 'application/json',
                                },
                                body: JSON.stringify({
                                  'Username': username,
                                  'Password': password,
                                })
                              });
        //console.log(response);
        if (response.status >= 200 && response.status < 300){
          let res = await response.text();
          //console.log(res);
          if(res){
            const resp = JSON.parse(res);
            setUserid(resp.userid);
            setName(resp.username);
            await setAccessToken(resp.accessToken);
            await setData(resp.registro);
            setLoadingData(false);
            localStorage.setItem("accessToken", resp.accessToken != null ? resp.accessToken : '')
            localStorage.setItem("refreshToken", resp.refreshToken != null ? resp.refreshToken : '')
            setUsername('');
            setPassword('');
            setLoadingBtn(false);
          }
        } else {
          setErrorMsg("Los datos de acceso no son correctos");
          setPassword('');
          setLoadingBtn(false);
        }
      } catch(error) {
          setLoadingBtn(false);
          setPassword('');
          setErrorMsg("Ha ocurrido un error inesperado");
          console.log(error);
      }
    }
  }

  const addRegistro = async (latitud, longitud, intentos) => {
    try {
      const token = await localStorage.getItem("accessToken");
      let response = await fetch(API_URI+'/usuario/add', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+token
          },
          body: JSON.stringify({
            'CheckTime': '',
            'CheckType': data && data.CheckType == 0 ? 1 : 0,
            'Latitud': latitud+'',
            'Longitud': longitud+'',
          })
      });
      if (response.status >= 200 && response.status < 300){
        let res = await response.text();
        if(res){
          const resp = JSON.parse(res);
          //console.log(resp);
          await setData(resp);
          setLoadingBtn(false);
        }
      }else if(response.status == 401){
        if(intentos < 1){
          await refreshToken();
          addRegistro(latitud, longitud, intentos+1); 
          setLoadingBtn(false);
        }else{
          setStatus('Ha ocurrido un error inesperado');
          setLoadingBtn(false);
        }
      }else{
        setStatus('Ha ocurrido un error inesperado');
        setLoadingBtn(false);
      }
    } catch(error) {
        setLoadingBtn(false);
        setStatus("Ha ocurrido un error inesperado");
        console.log(error);
    }
  }

  const onEntradaClick = async (e) => {
    e.preventDefault();
    setErrorMsg('');
    setLoadingBtn(true);
    if (!navigator.geolocation) {
      setStatus('La geolocalización no está soportada por este navegador');
      setLoadingBtn(false);
    } else {
      setStatus('Cargando ubicación...');
      navigator.geolocation.getCurrentPosition((position) => {
        setStatus(null);
        setLat(position.coords.latitude);
        setLng(position.coords.longitude);
        addRegistro(position.coords.latitude, position.coords.longitude, 0);
      }, () => {
        setStatus('No se puede obetener tu ubicación');
        setLoadingBtn(false);
      });
    }
  }

  if(loading) return (
    <div className="hero is-fullheight">
        <div className="hero-body is-justify-content-center is-align-items-center">
          <progress className="progress is-large is-primary" max="100">100%</progress>
        </div>
    </div>
  )
  else if(userid>0) return (
    <div className="hero is-fullheight">
        <div className="hero-body is-justify-content-center is-align-items-center">
            <div className="columns is-flex is-flex-direction-column box">
                <div className="column">
                  <h1 className="title has-text-centered">Control de presencia</h1>
                  <img src={logo} className="image" />
                </div>
                <div className="column has-text-centered">
                    <span className="icon"><i className="fas fa-user"></i></span>
                    <span>{name}(<a href="#" onClick={() =>{ cerrarSesion(); }}>Cerrar sesión</a>)</span>
                </div>
                <div className="column">
                  <h4 className="title has-text-centered is-4" style={{marginBottom:0}}>Última acción:</h4>
                  {loadingData ?
                  <p className="has-text-centered">Cargando...</p>
                  :
                  <p className="has-text-centered">{data && data.CheckType == 0 ? 'Salida' : data && data.CheckType == 1 ? 'Entrada' : ''} - {data && data.CheckTime ? data.CheckTime : ''}</p>
                  }
                </div>
                {data != null && data.CheckType == 0 ?
                <div className="column">
                    <button className={"button is-primary is-medium is-fullwidth" + (loadingBtn ? " is-loading" : "")} type="submit" onClick={onEntradaClick}>Entrada</button>
                </div>
                : null }
                {data != null && data.CheckType == 1 ?
                <div className="column">
                    <button className={"button is-danger is-medium is-fullwidth" + (loadingBtn ? " is-loading" : "")} type="submit" onClick={onEntradaClick}>Salida</button>
                </div>
                : null }
                {status && <div className="subtitle has-text-centered" style={{fontWeight:'bold'}}>{status}</div>}
            </div>
        </div>
    </div>
  )
  else return (
    <div className="hero is-fullheight">
        <div className="hero-body is-justify-content-center is-align-items-center">
            <div className="columns is-flex is-flex-direction-column box">
                <div className="column">
                  <h1 className="title has-text-centered">Control de presencia</h1>
                  <img src={logo} className="image" />
                </div>
                <div className="column">
                    <label htmlFor="email">Usuario</label>
                    <input 
                      className="input is-medium" 
                      placeholder="Nombre de usuario" 
                      type="text" 
                      id="username"
                      name="username"
                      onChange={(event) =>{
                        setUsername(event.target.value);
                      }}
                      value={username}
                    />
                </div>
                <div className="column">
                    <label htmlFor="Name">Contraseña</label>
                    <input 
                      className="input is-medium" 
                      type="password" 
                      placeholder="Clave pin" 
                      id="password"
                      name="password"
                      onChange={(event) =>{
                        setPassword(event.target.value);
                      }}
                      value={password}
                    />
                </div>
                <div className="column">
                    <button className={"button is-medium is-primary is-fullwidth " + (loadingBtn ? " is-loading" : "")} type="submit" onClick={onLoginClick}>Entrar</button>
                     {errorMsg !== '' ? <div className="subtitle has-text-centered" style={{color:'red',fontWeight:'bold'}}>{errorMsg}</div> : null }
                </div>
            </div>
        </div>
    </div>
  )
}

export default App;
